#assessment-analyze {
    .filter-bar{
        justify-content: flex-end;
        padding: 1em 0;

        .flex-col{
            margin-right: 1em;
        }

        .search-input{
            width: 20em;
        }
    }

    .threatAssessmentDetailsLink{
        color: black;
    }

    .threatAssessmentDetailsLink:hover{
        text-decoration: none;
        color: @buttonHoverBackground;
    }

    .chart{
        justify-content: center;
        
        @media (max-width : 1400px) {
            justify-content:left;
        }
    }

    .pie-chart-wrapper{
        @media (max-width : 400px) {
            display: none;
        }
    }

    .pie-chart{
        .pie {
            transform: scale(0.5) translate(50em, 25em);
            @media (max-width : 1400px) {
                transform: scale(0.5) translate(35em, 25em);
            }
            @media (max-width : 1000px) {
                transform: scale(0.2) translate(35em, 25em);
            }
        }

        .wrapper{
            transform: scale(1.5);
        }
    }

    .line-chart, .pie-chart{
        margin: 1em;
        h2{
            padding-top: .5em;
        }
    }

    .pie-label{
        font-size: 1em;
    }

    .excel{
        cursor: pointer;
        margin: 1em 0 0 1em;
      }
}