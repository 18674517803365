.answerTally{
    list-style-type: none;

    .threatAssessmentQuestion{
        margin-bottom: 1em;
    }

    .threatAssessmentQuestionText{
        font-weight: bold;
    }

    .overview-comments{
        list-style-type: none;
        margin-left: .8em;
        margin-top: .5em;
        font-size: .9em;
    }

    li.overview-comment:before{
        content: "- ";
    }


}