.fileviewer-dialog {
    .carousel{
        min-height: 30em;
    }
    
    .uploaded-file-container{
        margin: 1em;
    }

    .uploaded-file{
        max-height: 24em;
    }

    .uploaded-file-file{
        font-size: 5em;
        text-align: center;
    }

    .uploaded-file-caption{
        font-size: .7em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .download-button{
      text-align: center;
      margin-bottom: 1em;
    }
}
