#threatAssessments {
    color: black;
    padding-left: 1em;

    .threatAssessment {
        order: solid #9a9a9a 1px;
        padding: 1em;
        background-color: white;
        box-shadow: 0.1em 0.1em 0.01em #d9d7db;
        margin-top: 0.5em;
        color: black;
        justify-content: space-between;
    }

    .threatAssessmentClassification, .threatAssessmentComments{
        margin-top: .5em;
    }

    .threatAssessment:hover {
        background-color: #d9d7db;
    }

    .threatAssessmentDetailsLink{
        width: 8em;
    }

    .threatAssessments{
        margin: 0 0 5em 0;
    }

    .threatAssessmentHeadline{
        justify-content: space-between;
    }


    .create-threat-button-holder{
        margin: 1em;
    }

    .threatAssessmentInfo{
        width: 80%;
        justify-content: flex-start;
    }

    .threatAssessmentInfoComments{
        width: 80%;
        justify-content: flex-start;

        @media (max-width : 600px) {
            width: 100%;
        }
    }

    .threatAssessmentName{
        font-size: 1.5em;
        width: 10em;

        @media (max-width : 600px) {
            width: 5em;
        }
    }

    .threatAssessmentCreateDate{
        margin: 0 2em;
        @media (max-width : 600px) {
            margin: 0;
        }
    }

    .threatAssessmentTypeName, .threatAssessmentCreateDate, .threatAssessmentInstitutionName{
        width: 20%;

        @media (max-width : 600px) {
            width: unset;
        }
    }

    .assessmentTypeIcon{
        font-size: 1em;
        margin-right: .5em;
    }

    .no-archive{
        margin: .5em 0;
    }

    .no-achive-label{
        padding: 1em;
    }

    .search-bar{
        justify-content: space-between;
        margin-bottom: 1em;
        padding: 1em 0;
    }

    .search-fields{
        justify-content: flex-end;

        @media (max-width : 800px) {
            justify-content: flex-start;  
        }

    }

    .search-field {
        width: 30em;
        margin: .4em;

        @media (max-width : 800px) {
            width: 10em;   
        }
    }

    .search-field.search-field-small{
        width: 10em;
    }

    .search-button{
        margin-right: 1em;
        margin-top: 1em;
    }

    .open-button{
        @media (max-width : 600px) {
            display: none;
        }
    }
}