@import "./color";
@import "./Material.less";
@import "./Message";
@import "./Header";
@import "./AppError";
@import "./Loader";
@import "./HelpToolTip";
@import "./SideNav";
@import "./DotMenu";
@import "./DotMenuItem";
@import "./AnswerTally";
@import "./Banner.less";
@import "./OtherDropDown.less";
@import "./ActionButton.less";
@import "./NotifyTemplateDialog";
@import "./threatAssessmentCreate";
@import "./threatAssessmentEdit";
@import "./AssessmentTabs";
@import "./threatAssessmentDetail";
@import "./threatAssessmentInterview";
@import "./threatAssessmentList";
@import "./threatAssessmentReport";
@import "./threatAssessmentResolve";
@import "./threatAssessmentNotes";
@import "./threatAssessmentReview";
@import "./threatAssessmentAnalyze";
@import "./Acknowledge";
@import "./FileViewer";
@import "./AssessmentForm";
@import "./Tutorial.less";

#app{
  background: @frontground;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

label{
  font-weight: bold;
}

h1 {
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

html
{
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.flex {
  display: flex;
}

.flex-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-justify-space-between{
  display: flex;
  justify-content: space-between;
}

.flex-no-grow{
  flex-grow: 0 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.app {
  min-height: 100%;
  width: 100%;
}

.content {
  min-height: 100% !important;
  width: 100%;
}

.main-container {
  min-height: 75em;
  background: @frontground;
  border: 0px solid #000000;
  padding-left: 18em;
  padding-right: 2em;
  padding-bottom: 5em;
  padding-top: 1em;
  width:100%;

}

@media (max-width : 1000px) {
  .main-container {
    padding: .5em;
  }
}

.boldLink{
  text-decoration: none; 
  color: blue;
}

.no-sidenav{
  padding: .5em;
}


  h1 {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
   color: @textcolor;
   font-size: 2em;
   margin-top: .5em;
   margin-bottom: .5em;
  }

  h2 {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
   color: @textcolor;
   font-size: 1.5em;
  }

  .clickable{
    cursor: pointer;
  }

  .btn-logout {
    border: solid black 0.1em;
    padding: .5em 1em;
    color: black;
    margin: auto;
    width: 75px;
    text-align: center;
    padding: .5em;
    cursor: pointer;
    text-decoration: none;
    background: @headlines;
    float: right;
    margin-top: 1.8em;
    margin-right: 3em;

    @media only screen and (max-width : 1000px) {
      display: none;
    }
  }

  li {
    text-decoration: none;
  }

  li a {
      display: block;
      color: white;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
  }
  
  .successMessage{
    font-weight: bold;
    color: @correctAnswerText;
  }

  .ai-generated-label{
    background-color: darkblue;
    border-radius: .5em;
    color: white;
    padding: .2em;
  }

  .ai-generated-warning{
    margin-top: .2em;
    font-size: .6em;
    font-weight: 300;
  }

  .errorMessage{
    font-weight: bold;
    color: @headlines;
  }

  label{
    margin-right: .5em;
  }
 

  .dialog-title {
    font-weight: bold;
  }

  .subText{
    font-weight: 300;
  }

  .paper {
    margin-bottom: 1em;
    padding: 1em;

    h2{
      margin-top: 0;
    }
  }

  .section{
    margin-top:1em;
  }

  .debug{
    display: none;
  }

  @media  print {
    body{
      width: 10em;
    }
    body * {
      visibility: hidden;
    }


    .no-print, .no-print * {
      visibility: hidden !important;
    }

    .print, .print * {
      visibility: visible !important;
    }

    .no-print-hide {
      display: none;
    }

    .main-container, .main-container * {
      visibility: visible;
    }

    .main-container{
      position: absolute;
      left: -1em;
      top: 0;
    }
  }

