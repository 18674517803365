#threatAssessmentReview{
    
    .flex-col{
        width: 100%;
    }
    
    .action{
        width: 20em;
        margin-right: 1em;
    }

}