#assessmentTabRouter {
    margin-bottom: 1em;

    h1{
        margin-bottom: 0;
    }

    h2{
        margin: 0 1em .5em 0;
    }

    .assessment-header{
        justify-content: space-between;

        @media print {
            margin-left: 1em;
        }
    }
    .close-case{
        margin-right: 2em;
        margin-bottom: 2em; 

        @media (max-width : 600px) {
            margin-right: 8em;   
            width: 100%;
        }
    }

    .actions{
        justify-content: flex-end;
        width: 100%;

        .flex-col{
            width: auto;
            padding: .8em 1em .5em 0;
        }
    }

    .perform-interview-list{
        z-index: 100;
        padding: 0;
    }

    .perform-interview{
        position: relative;
        color: @textcolor;
        z-index: 100;


        .fa-comment{
            position: absolute;
        }

        .perform-interview-text{
            margin-left: 2em;
        }
    }

    .case-closed{
        color: orangered;
        font-size: 1em;
    }

    #menu-col{
        padding-top: .5em;
        position: relative;
        display: inline-block;
        width: 3em;
    }

    .menu{
        position: absolute;
        right: 0;
        z-index: 99;
    }

    .menu-item{
        width: 14em;
    }

   .fa-box-open, .fa-box-archive{
    width: 1.5em;
   }
}

.share-dialog{
    padding: 0 2em 2em 2em;
    text-align: center;
  
    .shareEmail{
        min-width: 20em;
    }

    .share-dialog-email{
      margin-top: 1em;
    }
  
    .share-button-container{
      margin-top: 1em;
    }
}