.banner{
    .banner-container{
        padding: 1.5em;
    }

    .title{
        font-size: 2em;
    }

    .message{
        font-size: 1em;
    }

    .todo{
        margin-top: 1em;
        background-color: white;
    }

    .warning{
        color: white;
        background-color: #ff9800;
    }

    .info{
        color: white;
        background-color: #2196f3
    }
}