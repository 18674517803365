.MuiButton-outlinedPrimary {
  color: black !important;
}
.MuiButton-outlinedPrimary:hover {
  color: white !important;
}
.MuiFab-primary {
  background-color: #a90004 !important;
}
.MuiButton-root:hover {
  color: white;
  background: #a90004 !important;
}
.MuiInputLabel-root {
  background-color: white;
}
.MuiCircularProgress-colorPrimary {
  color: #a90004 !important;
}
.Mui-checked {
  color: #a90004 !important;
}
.MuiTextField-root {
  width: 100%;
}
.MuiFormHelperText-root {
  margin-top: 0;
  margin-left: 0;
}
.material-icons {
  font-size: 1.2em;
  margin-right: 0.5em;
}
#toast {
  position: fixed;
  top: 7em;
  width: 30em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.95;
  z-index: 99;
}
#toast .title {
  font-size: 1.2em;
  font-weight: bold;
  display: inline;
  margin-right: 1em;
}
#toast .toast {
  padding: 1em;
}
#toast .success {
  background-color: #61b961;
}
#toast .error {
  background-color: #ce696b;
}
#toast .message {
  display: inline;
}
#toast .close-button {
  position: absolute;
  top: 0.1em;
  right: 0.2em;
  opacity: 0.6;
}
#toast .fa {
  color: white;
  font-size: 1.2em;
}
header .logo {
  font-size: 2em;
  margin-left: 2em;
}
@media (max-width: 1000px) {
  header .logo {
    font-size: 1.2em;
    margin-top: 0.4em;
    margin-left: 2.5em;
  }
}
header .logo-red {
  color: #f8f2ee;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
  margin-top: 0.7em;
}
header .logo-icon {
  margin-top: 0.7em;
}
header .logo-icon img {
  height: 1.4em;
  border-radius: 10em;
}
header .logo-white {
  color: white;
  margin-left: 0.2em;
}
header .product-title {
  color: #f8f2ee;
  margin-left: 1em;
  margin-top: 0.7em;
}
header .wrapper {
  justify-content: space-between;
  width: 100%;
  background-color: #3f3f3f;
  height: 6em;
}
@media (max-width: 1000px) {
  header .wrapper {
    height: 4em;
  }
}
header .link-back-login {
  padding-left: 2em;
  padding-top: 1.8em;
}
@media (max-width: 1000px) {
  header .link-back-login {
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1.1em;
  }
}
header .back-login {
  font-size: 2em;
  color: white;
}
header .need-help {
  color: white;
  margin-top: 2.3em;
  margin-right: 1em;
  cursor: pointer;
}
@media (max-width: 1000px) {
  header .need-help {
    margin-top: 1.3em;
    display: none;
  }
}
header .need-help:hover {
  color: #a90004;
}
#app-error {
  display: inline-block;
  width: 100%;
}
#app-error .error-message {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10em;
  width: 30%;
  background-color: #ce696b;
  padding: 2em;
}
#loader {
  display: inline-block;
  width: 100%;
}
#loader .load-icon {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 2em;
  padding: 2em;
  font-size: 4em;
}
.helpToolTip {
  display: inline;
}
.helpToolTip .helpicon {
  font-size: 0.6em;
  position: relative;
  top: -5px;
  background-color: lightgrey;
  color: black;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  margin-right: auto;
  margin-left: 1em;
  margin-top: 1em;
  cursor: pointer;
}
#sidebar {
  background: #2f2e2e;
  height: 100%;
  min-height: 100em;
  top: 6em;
  position: absolute;
  border: 0px solid #000000;
}
#sidebar .module {
  white-space: pre-wrap;
  position: relative;
  border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  border: 0px solid #000000;
  color: #cccccc;
  height: 4em;
  text-align: left;
  padding: 1em;
  width: 15em;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#22262b+0,091324+100 */
  text-decoration: none;
  cursor: pointer;
}
#sidebar .module a:link {
  color: #cccccc;
}
#sidebar .module a:hover {
  color: white;
}
#sidebar .module.active {
  background: #f8f2ee !important;
  color: black !important;
}
#sidebar .module.active a:hover {
  color: black;
}
#sidebar .linkStyle {
  width: 13em;
}
@media (max-width: 1000px) {
  #sidebar .linkStyle {
    padding-right: 2em;
  }
}
#sidebar .complete-check {
  position: absolute;
  color: green;
  left: 8em;
  bottom: 2.1em;
  font-size: 1.8em;
}
#sidebar div {
  white-space: pre-wrap;
}
#sidebar div a:link {
  color: white;
}
#sidebar div a:hover {
  color: white !important;
}
#sidebar .showNav-bar {
  position: absolute;
  top: 0.9em;
  left: 1.5em;
  font-size: 2em;
  color: white;
}
@media (max-width: 1000px) {
  #sidebar .showNav-bar {
    top: 0.6em;
  }
}
#sidebar .mobile-nav {
  position: absolute;
  top: 4em;
  background-color: black;
  z-index: 99;
}
#sidebar .desktop {
  display: block;
}
#sidebar .tablet {
  display: none;
}
#sidebar .tablet .btn {
  height: 7em;
  font-size: 14px;
  display: block;
}
#sidebar .tablet .complete-check {
  left: 8em;
  height: 1.2em;
}
@media (max-width: 1000px) {
  #sidebar .desktop {
    display: none;
  }
  #sidebar .tablet {
    display: block;
  }
}
@media (max-width: 1000px) {
  #sidebar {
    width: 0;
    top: 0;
  }
}
.menu .opener {
  font-size: 1.5em;
  background-color: white;
  color: black;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
}
.menu .opener:hover {
  color: darkgray;
}
.menu .open {
  display: none;
}
.menu-item {
  font-size: 1.2em;
  background-color: white;
  color: black;
  padding: 0.5em 1em;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  width: 11em;
}
.menu-item:hover {
  color: darkgray;
}
.answerTally {
  list-style-type: none;
}
.answerTally .threatAssessmentQuestion {
  margin-bottom: 1em;
}
.answerTally .threatAssessmentQuestionText {
  font-weight: bold;
}
.answerTally .overview-comments {
  list-style-type: none;
  margin-left: 0.8em;
  margin-top: 0.5em;
  font-size: 0.9em;
}
.answerTally li.overview-comment:before {
  content: "- ";
}
.banner .banner-container {
  padding: 1.5em;
}
.banner .title {
  font-size: 2em;
}
.banner .message {
  font-size: 1em;
}
.banner .todo {
  margin-top: 1em;
  background-color: white;
}
.banner .warning {
  color: white;
  background-color: #ff9800;
}
.banner .info {
  color: white;
  background-color: #2196f3;
}
.otherDropDown .other-dropdown-text {
  width: 80%;
  margin-right: 1em;
}
.otherDropDown .other-dropdown-text-container {
  width: 100%;
}
.ActionButton .popper {
  z-index: 100;
}
.notifyTemplateDialog .templates {
  margin-bottom: 5em;
  z-index: 100;
}
.notifyTemplateDialog .menu-item {
  width: auto;
}
.notifyTemplateDialog .memu-list {
  margin-left: 2em;
}
.notifyTemplateDialog .uploaded-file-container {
  position: relative;
  width: 5em;
  margin-right: 2em;
  flex-grow: 0;
  border-bottom: 0;
}
.notifyTemplateDialog .uploaded-file {
  color: black;
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
  width: 1em;
}
.notifyTemplateDialog .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notifyTemplateDialog .delete-image {
  position: absolute;
  top: 0em;
  right: 0em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
  color: black;
}
#threatAssessmentCreate {
  padding-left: 1em;
}
#threatAssessmentCreate .add-another {
  margin-bottom: 2em;
}
#threatAssessmentCreate .addAnotherOther {
  font-size: 0.8em;
  color: darkgray;
  margin-top: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
#threatAssessmentCreate .flex-row {
  justify-content: space-between;
}
#threatAssessmentCreate .flex-row .flex-col {
  margin: 0.5em 1em 0.5em 0;
}
#threatAssessmentCreate .flex-row .flex-col:last-child {
  margin: 0.5em 0 0.5em 0;
}
#threatAssessmentCreate .flex-col {
  flex-grow: 1;
  min-width: 10em;
}
@media (max-width: 600px) {
  #threatAssessmentCreate .flex-col {
    flex-grow: 0;
  }
}
#threatAssessmentCreate .assessedFirstName-container {
  flex-grow: 2;
}
#threatAssessmentCreate .assessedLastName-container {
  flex-grow: 2;
}
#threatAssessmentCreate .assessmentFinding-text {
  width: 8em;
  margin-top: 1.2em;
}
#threatAssessmentCreate .assessmentFinding-reviewed {
  width: 4em;
}
#threatAssessmentCreate .reason,
#threatAssessmentCreate .dateOfBirth,
#threatAssessmentCreate .threatAssessmentTypeId,
#threatAssessmentCreate .institutionBuildingId,
#threatAssessmentCreate .institutionId,
#threatAssessmentCreate .requestorTitle,
#threatAssessmentCreate .gender,
#threatAssessmentCreate .threatLocation {
  width: 5em;
}
#threatAssessmentCreate .threatPersonType {
  width: 15em;
}
@media (max-width: 800px) {
  #threatAssessmentCreate .threatPersonType {
    width: 5em;
  }
}
#threatAssessmentCreate .threatPersonType-row {
  width: 40em;
}
@media (max-width: 800px) {
  #threatAssessmentCreate .threatPersonType-row {
    width: 100%;
  }
}
#threatAssessmentCreate .delegateTitle {
  justify-content: flex-start;
  min-width: auto;
  flex-grow: 0;
}
#threatAssessmentCreate .hasSubtitle {
  margin-bottom: 0;
}
#threatAssessmentCreate .subText {
  color: darkgray;
}
#threatAssessmentCreate .interview-assign-dropdown {
  flex-grow: 9;
}
.dialog {
  padding: 0 2em 2em 2em;
  text-align: center;
}
.dialog .dialog-text {
  margin-top: 1em;
}
.dialog .case-list {
  margin-top: 1em;
}
.dialog .case-links {
  list-style-type: none;
  padding: 0;
}
.dialog .case-links li a {
  padding: 0;
  color: black;
}
.dialog .header {
  font-weight: bold;
}
.dialog .similar-pdf {
  margin-left: 1em;
}
#threatAssessmentEdit {
  padding-left: 1em;
}
#threatAssessmentEdit .add-another {
  margin-bottom: 2em;
}
#threatAssessmentEdit .addAnotherOther {
  font-size: 0.8em;
  color: darkgray;
  margin-top: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
#threatAssessmentEdit .flex-row {
  justify-content: space-between;
}
#threatAssessmentEdit .flex-row .flex-col {
  margin: 0.5em 1em 0.5em 0;
}
#threatAssessmentEdit .flex-row .flex-col:last-child {
  margin: 0.5em 0 0.5em 0;
}
#threatAssessmentEdit .flex-col {
  flex-grow: 1;
  min-width: 10em;
}
@media (max-width: 600px) {
  #threatAssessmentEdit .flex-col {
    flex-grow: 0;
  }
}
#threatAssessmentEdit .assessedFirstName-container {
  flex-grow: 2;
}
#threatAssessmentEdit .assessedLastName-container {
  flex-grow: 2;
}
#threatAssessmentEdit .assessmentFinding-text {
  width: 8em;
  margin-top: 1.2em;
}
#threatAssessmentEdit .assessmentFinding-reviewed {
  width: 4em;
}
#threatAssessmentEdit .reason,
#threatAssessmentEdit .dateOfBirth,
#threatAssessmentEdit .threatAssessmentTypeId,
#threatAssessmentEdit .institutionBuildingId,
#threatAssessmentEdit .institutionId,
#threatAssessmentEdit .requestorTitle,
#threatAssessmentEdit .gender,
#threatAssessmentEdit .threatLocation {
  width: 5em;
}
#threatAssessmentEdit .threatPersonType {
  width: 15em;
}
@media (max-width: 800px) {
  #threatAssessmentEdit .threatPersonType {
    width: 5em;
  }
}
#threatAssessmentEdit .threatPersonType-row {
  width: 40em;
}
@media (max-width: 800px) {
  #threatAssessmentEdit .threatPersonType-row {
    width: 100%;
  }
}
#threatAssessmentEdit .delegateTitle {
  justify-content: flex-start;
  min-width: auto;
  flex-grow: 0;
}
#threatAssessmentEdit .hasSubtitle {
  margin-bottom: 0;
}
#threatAssessmentEdit .subText {
  color: darkgray;
}
.dialog {
  padding: 0 2em 2em 2em;
  text-align: center;
}
.dialog .dialog-text {
  margin-top: 1em;
}
.dialog .case-list {
  margin-top: 1em;
}
.dialog .case-links {
  list-style-type: none;
  padding: 0;
}
.dialog .case-links li a {
  padding: 0;
  color: black;
}
.dialog .header {
  font-weight: bold;
}
#assessmentTabRouter {
  margin-bottom: 1em;
}
#assessmentTabRouter h1 {
  margin-bottom: 0;
}
#assessmentTabRouter h2 {
  margin: 0 1em 0.5em 0;
}
#assessmentTabRouter .assessment-header {
  justify-content: space-between;
}
@media print {
  #assessmentTabRouter .assessment-header {
    margin-left: 1em;
  }
}
#assessmentTabRouter .close-case {
  margin-right: 2em;
  margin-bottom: 2em;
}
@media (max-width: 600px) {
  #assessmentTabRouter .close-case {
    margin-right: 8em;
    width: 100%;
  }
}
#assessmentTabRouter .actions {
  justify-content: flex-end;
  width: 100%;
}
#assessmentTabRouter .actions .flex-col {
  width: auto;
  padding: 0.8em 1em 0.5em 0;
}
#assessmentTabRouter .perform-interview-list {
  z-index: 100;
  padding: 0;
}
#assessmentTabRouter .perform-interview {
  position: relative;
  color: black;
  z-index: 100;
}
#assessmentTabRouter .perform-interview .fa-comment {
  position: absolute;
}
#assessmentTabRouter .perform-interview .perform-interview-text {
  margin-left: 2em;
}
#assessmentTabRouter .case-closed {
  color: orangered;
  font-size: 1em;
}
#assessmentTabRouter #menu-col {
  padding-top: 0.5em;
  position: relative;
  display: inline-block;
  width: 3em;
}
#assessmentTabRouter .menu {
  position: absolute;
  right: 0;
  z-index: 99;
}
#assessmentTabRouter .menu-item {
  width: 14em;
}
#assessmentTabRouter .fa-box-open,
#assessmentTabRouter .fa-box-archive {
  width: 1.5em;
}
.share-dialog {
  padding: 0 2em 2em 2em;
  text-align: center;
}
.share-dialog .shareEmail {
  min-width: 20em;
}
.share-dialog .share-dialog-email {
  margin-top: 1em;
}
.share-dialog .share-button-container {
  margin-top: 1em;
}
#threatAssessment h2 {
  margin-bottom: 0;
}
#threatAssessment .flex-row {
  width: 80%;
  justify-content: space-between;
}
@media (max-width: 600px) {
  #threatAssessment .flex-row {
    width: 100%;
  }
}
#threatAssessment .columns .flex-row {
  width: 60%;
  justify-content: space-between;
}
@media (max-width: 600px) {
  #threatAssessment .columns .flex-row {
    width: 100%;
  }
}
#threatAssessment .columns .flex-col {
  width: 30%;
}
#threatAssessment .flex-col {
  padding-top: 1em;
}
#threatAssessment a {
  color: black;
}
#threatAssessment a:hover {
  color: #a90004;
}
#threatAssessment .grade {
  color: black;
  margin-bottom: 1em;
}
#threatAssessment .instructions {
  margin-top: 1em;
  margin-right: 3em;
}
#threatAssessment .close-case {
  margin-right: 2em;
  margin-bottom: 2em;
}
@media (max-width: 600px) {
  #threatAssessment .close-case {
    margin-right: 8em;
    width: 100%;
  }
}
@media (max-width: 600px) {
  #threatAssessment .delete {
    margin-right: 8em;
  }
}
#threatAssessment .btn-next {
  margin: 0;
}
#threatAssessment .btn-next:hover {
  color: white;
}
#threatAssessment .invite-interview {
  width: 20em;
}
#threatAssessment .invite-button {
  margin-left: 1em;
}
#threatAssessment .interview {
  color: black;
}
#threatAssessment .actions {
  justify-content: flex-end;
  width: 100%;
}
#threatAssessment .actions .flex-col {
  width: auto;
  padding: 1em 1em 1em 0;
}
#threatAssessment .case-closed {
  color: orangered;
  margin-bottom: 0.5em;
}
#threatAssessment .files {
  margin: 0 0 5em 0;
}
#threatAssessment .assets {
  justify-content: flex-start;
}
#threatAssessment .uploaded-file-container {
  position: relative;
  width: 8em;
  margin-right: 2em;
  flex-grow: 0;
  border-bottom: 0;
  cursor: pointer;
}
#threatAssessment .uploaded-file {
  color: black;
  font-size: 3em;
  padding: 0.4em;
  cursor: pointer;
  border: solid 0.01em gray;
  width: 1em;
}
#threatAssessment .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#threatAssessment .delete-image {
  position: absolute;
  top: 0.1em;
  right: 1.5em;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0.3em;
  background: white;
  border-radius: 1em;
  color: black;
}
#threatAssessment .file-upload-form {
  margin-top: 1em;
}
#threatAssessment .btn-upload {
  width: 10em;
  margin-top: 1em;
}
#threatAssessment .input-file {
  color: black;
}
#threatAssessment .interview-text {
  padding: 0.5em 0;
}
#threatAssessment .list-link {
  display: none;
}
@media (max-width: 600px) {
  #threatAssessment .list-link {
    display: inline-block;
  }
}
#threatAssessment .close-supplemental {
  margin-left: 6em;
  margin-top: 2.3em;
}
#threatAssessment textarea {
  height: 4em;
}
#threatAssessment .finding-item {
  margin-right: 1em;
}
#threatAssessment .findings {
  list-style-type: none;
  margin: 0;
}
#threatAssessment .finding-row {
  width: 100%;
}
#threatAssessment .dropzonearea {
  margin-bottom: 1em;
  width: 50%;
  min-width: 40em;
}
#threatAssessment .informationSource {
  width: 16em;
  display: inline-block;
}
@media (max-width: 600px) {
  #threatAssessment .informationSource {
    width: 100%;
  }
}
#threatAssessment .reviewed {
  width: 8em;
  display: inline-block;
}
#threatAssessment .comments {
  width: 100% !important;
}
#threatAssessment .form-text {
  vertical-align: text-bottom;
}
#threatAssessment .complete-check {
  margin-left: 0.3em;
  color: green;
  font-size: 1.2em;
}
#threatAssessment .review:nth-child(n+2) {
  margin-top: 0.5em;
}
.share-dialog {
  padding: 0 2em 2em 2em;
  text-align: center;
}
.share-dialog .shareEmail {
  min-width: 20em;
}
.share-dialog .share-dialog-email {
  margin-top: 1em;
}
.share-dialog .share-button-container {
  margin-top: 1em;
}
#threatAssessmentInterview {
  color: black;
  padding-left: 1em;
}
#threatAssessmentInterview form {
  margin-bottom: 3em;
}
#threatAssessmentInterview .flex-row {
  justify-content: space-between;
}
#threatAssessmentInterview .flex-row .flex-col {
  margin: 0.5em 1em 0.5em 0;
}
#threatAssessmentInterview .flex-row .flex-col:last-child {
  margin: 0.5em 0 0.5em 0;
}
#threatAssessmentInterview .flex-col {
  flex-grow: 1;
  min-width: 10em;
}
@media (max-width: 600px) {
  #threatAssessmentInterview .flex-col {
    flex-grow: 0;
  }
}
#threatAssessmentInterview .helpToolTip {
  font-size: 0.6em;
}
#threatAssessmentInterview textarea {
  height: 2em;
}
#threatAssessmentInterview .interviewQuestions {
  padding-left: 0;
  margin-left: 0;
}
#threatAssessmentInterview .interviewQuestion {
  margin-left: 1.5em;
}
#threatAssessmentInterview .interviewQuestion-answer {
  margin-right: 1em;
}
#threatAssessmentInterview .interviewQuestion-subText {
  font-weight: 300;
  font-size: 0.8em;
  color: darkgray;
}
#threatAssessmentInterview .interviewQuestion-footerText {
  font-weight: bold;
}
#threatAssessmentInterview .noshow {
  color: gray;
}
#threatAssessmentInterview .save-container {
  margin-top: 1em;
}
#threatAssessmentInterview .previouslyAnswered {
  color: darkgray;
  font-size: 0.8em;
}
#threatAssessmentInterview .answer-text {
  margin-left: 0.5em;
}
#threatAssessmentInterview .flex-content {
  margin-top: 1em;
}
#threatAssessmentInterview .input-label {
  margin-right: 0.5em;
}
#threatAssessmentInterview .radio-label {
  margin-left: 0.5em;
  margin-right: 2em;
}
#threatAssessmentInterview .header {
  font-size: 1.1em;
  font-weight: bold;
}
#threatAssessments {
  color: black;
  padding-left: 1em;
}
#threatAssessments .threatAssessment {
  order: solid #9a9a9a 1px;
  padding: 1em;
  background-color: white;
  box-shadow: 0.1em 0.1em 0.01em #d9d7db;
  margin-top: 0.5em;
  color: black;
  justify-content: space-between;
}
#threatAssessments .threatAssessmentClassification,
#threatAssessments .threatAssessmentComments {
  margin-top: 0.5em;
}
#threatAssessments .threatAssessment:hover {
  background-color: #d9d7db;
}
#threatAssessments .threatAssessmentDetailsLink {
  width: 8em;
}
#threatAssessments .threatAssessments {
  margin: 0 0 5em 0;
}
#threatAssessments .threatAssessmentHeadline {
  justify-content: space-between;
}
#threatAssessments .create-threat-button-holder {
  margin: 1em;
}
#threatAssessments .threatAssessmentInfo {
  width: 80%;
  justify-content: flex-start;
}
#threatAssessments .threatAssessmentInfoComments {
  width: 80%;
  justify-content: flex-start;
}
@media (max-width: 600px) {
  #threatAssessments .threatAssessmentInfoComments {
    width: 100%;
  }
}
#threatAssessments .threatAssessmentName {
  font-size: 1.5em;
  width: 10em;
}
@media (max-width: 600px) {
  #threatAssessments .threatAssessmentName {
    width: 5em;
  }
}
#threatAssessments .threatAssessmentCreateDate {
  margin: 0 2em;
}
@media (max-width: 600px) {
  #threatAssessments .threatAssessmentCreateDate {
    margin: 0;
  }
}
#threatAssessments .threatAssessmentTypeName,
#threatAssessments .threatAssessmentCreateDate,
#threatAssessments .threatAssessmentInstitutionName {
  width: 20%;
}
@media (max-width: 600px) {
  #threatAssessments .threatAssessmentTypeName,
  #threatAssessments .threatAssessmentCreateDate,
  #threatAssessments .threatAssessmentInstitutionName {
    width: unset;
  }
}
#threatAssessments .assessmentTypeIcon {
  font-size: 1em;
  margin-right: 0.5em;
}
#threatAssessments .no-archive {
  margin: 0.5em 0;
}
#threatAssessments .no-achive-label {
  padding: 1em;
}
#threatAssessments .search-bar {
  justify-content: space-between;
  margin-bottom: 1em;
  padding: 1em 0;
}
#threatAssessments .search-fields {
  justify-content: flex-end;
}
@media (max-width: 800px) {
  #threatAssessments .search-fields {
    justify-content: flex-start;
  }
}
#threatAssessments .search-field {
  width: 30em;
  margin: 0.4em;
}
@media (max-width: 800px) {
  #threatAssessments .search-field {
    width: 10em;
  }
}
#threatAssessments .search-field.search-field-small {
  width: 10em;
}
#threatAssessments .search-button {
  margin-right: 1em;
  margin-top: 1em;
}
@media (max-width: 600px) {
  #threatAssessments .open-button {
    display: none;
  }
}
#threatAssessmentReport {
  color: black;
}
#threatAssessmentReport .MuiPaper-root {
  padding: 1em;
  margin-top: 1em;
}
#threatAssessmentReport h2 {
  margin: 0;
}
#threatAssessmentReport .fa-edit {
  margin-left: 1em;
  font-size: 0.7em;
  color: black;
}
#threatAssessmentReport .threat-bar {
  height: 1.4em;
  padding-left: 2em;
}
#threatAssessmentReport .threat-bar-holder {
  width: 70%;
  border: solid #eee9e9 1px;
}
#threatAssessmentReport .rating-label {
  position: absolute;
}
#threatAssessmentReport ol,
#threatAssessmentReport ul {
  padding-left: 0;
}
#threatAssessmentReport li {
  float: none;
}
#threatAssessmentReport .threatAssessmentAssessedFirstName {
  margin-left: 1em;
}
#threatAssessmentReport .threatAssessmentAssessedLastName {
  margin-left: 1em;
}
#threatAssessmentReport .interviewQuestion-subText {
  font-weight: 300;
  font-size: 0.8em;
  color: darkgray;
}
#threatAssessmentReport .interview {
  padding-bottom: 1em;
}
#threatAssessmentReport .interview-header,
#threatAssessmentReport .answer-tally-header {
  font-size: 1.3em;
  font-weight: bold;
}
#threatAssessmentReport .threat-answer-tally {
  margin-top: 1em;
}
#threatAssessmentReport .question {
  padding: 1em 0;
}
#threatAssessmentReport .questionText {
  font-weight: bold;
}
#threatAssessmentReport .answer {
  font-size: 0.9em;
}
#threatAssessmentReport .fa-check {
  margin-left: 0.2em;
  color: red;
}
#threatAssessmentReport .interviewQuestion-footerText {
  font-weight: bold;
}
#threatAssessmentReport .resolutionClassification {
  font-weight: bold;
}
#threatAssessmentReport .actions {
  justify-content: flex-end;
  width: 100%;
}
#threatAssessmentReport .actions .flex-col {
  flex-grow: 0;
  width: auto;
}
#threatAssessmentReport .cannotResolveMessage {
  color: #a90004;
}
#threatAssessmentResolve {
  color: black;
  padding-left: 1em;
}
#threatAssessmentResolve h2 {
  margin-bottom: 0;
}
#threatAssessmentResolve .subText {
  font-weight: 300;
  font-size: 0.8em;
  color: darkgray;
}
#threatAssessmentResolve form {
  margin-bottom: 3em;
}
#threatAssessmentResolve .resolutionQuestions,
#threatAssessmentResolve .interventionQuestions {
  padding-left: 0;
  color: black;
  margin-left: 1em;
}
#threatAssessmentResolve .resolutionQuestion {
  margin-top: 1em;
}
#threatAssessmentResolve .interviewQuestion {
  margin-top: 1em;
}
#threatAssessmentResolve .resolutionQuestion-subText {
  font-weight: 300;
  font-size: 0.8em;
  color: darkgray;
}
#threatAssessmentResolve .resolutionQuestion-footerText {
  font-weight: bold;
}
#threatAssessmentResolve .noshow {
  color: gray;
}
#threatAssessmentResolve .save-container {
  margin-top: 1em;
}
#threatAssessmentResolve .actionPlanOption {
  display: block;
  font-weight: normal;
}
#threatAssessmentResolve .behaviorPlanParticipantOption {
  display: block;
  font-weight: normal;
  width: 10em;
}
#threatAssessmentResolve .behaviorPlanParticipant-checkbox {
  margin-right: 1em;
}
#threatAssessmentResolve .other-checkbox {
  margin-right: 1em;
}
#threatAssessmentResolve .behaviorPlanParticipant-comment {
  width: 40em;
}
#threatAssessmentResolve .actionPlans {
  margin: 0;
  padding-left: 1em;
}
#threatAssessmentResolve .resolution-notes-section {
  width: 70%;
}
#threatAssessmentNotes {
  color: black;
  padding-left: 1em;
}
#threatAssessmentNotes h2 {
  margin-bottom: 0;
}
#threatAssessmentNotes .resolution-notes-section {
  width: 70%;
}
#threatAssessmentReview .flex-col {
  width: 100%;
}
#threatAssessmentReview .action {
  width: 20em;
  margin-right: 1em;
}
#assessment-analyze .filter-bar {
  justify-content: flex-end;
  padding: 1em 0;
}
#assessment-analyze .filter-bar .flex-col {
  margin-right: 1em;
}
#assessment-analyze .filter-bar .search-input {
  width: 20em;
}
#assessment-analyze .threatAssessmentDetailsLink {
  color: black;
}
#assessment-analyze .threatAssessmentDetailsLink:hover {
  text-decoration: none;
  color: #a90004;
}
#assessment-analyze .chart {
  justify-content: center;
}
@media (max-width: 1400px) {
  #assessment-analyze .chart {
    justify-content: left;
  }
}
@media (max-width: 400px) {
  #assessment-analyze .pie-chart-wrapper {
    display: none;
  }
}
#assessment-analyze .pie-chart .pie {
  transform: scale(0.5) translate(50em, 25em);
}
@media (max-width: 1400px) {
  #assessment-analyze .pie-chart .pie {
    transform: scale(0.5) translate(35em, 25em);
  }
}
@media (max-width: 1000px) {
  #assessment-analyze .pie-chart .pie {
    transform: scale(0.2) translate(35em, 25em);
  }
}
#assessment-analyze .pie-chart .wrapper {
  transform: scale(1.5);
}
#assessment-analyze .line-chart,
#assessment-analyze .pie-chart {
  margin: 1em;
}
#assessment-analyze .line-chart h2,
#assessment-analyze .pie-chart h2 {
  padding-top: 0.5em;
}
#assessment-analyze .pie-label {
  font-size: 1em;
}
#assessment-analyze .excel {
  cursor: pointer;
  margin: 1em 0 0 1em;
}
#acknowledge .signature {
  width: 30em;
}
#acknowledge .signature-date {
  font-size: 1.5em;
  margin-left: 1em;
  margin-top: 0.5em;
}
.fileviewer-dialog .carousel {
  min-height: 30em;
}
.fileviewer-dialog .uploaded-file-container {
  margin: 1em;
}
.fileviewer-dialog .uploaded-file {
  max-height: 24em;
}
.fileviewer-dialog .uploaded-file-file {
  font-size: 5em;
  text-align: center;
}
.fileviewer-dialog .uploaded-file-caption {
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileviewer-dialog .download-button {
  text-align: center;
  margin-bottom: 1em;
}
#assessmentForm {
  width: 100%;
}
#assessmentForm .highlighted-instructions {
  font-size: 1.2em;
  color: #a2114b;
}
#assessmentForm .timeStamp {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 1em;
}
#assessmentForm .reviewRequestAction {
  font-weight: bold;
  display: inline-block;
}
#assessmentForm .reviewMessage {
  margin-bottom: 1em;
}
#assessmentForm .flex-row {
  justify-content: space-between;
}
#assessmentForm .flex-row .flex-col {
  margin: 0.5em 1em 0.5em 0;
}
#assessmentForm .flex-row .flex-col:last-child {
  margin: 0.5em 0 0.5em 0;
}
#assessmentForm .flex-col {
  flex-grow: 1;
  min-width: 10em;
}
@media (max-width: 600px) {
  #assessmentForm .flex-col {
    flex-grow: 0;
  }
}
#assessmentForm .stop {
  margin-bottom: 1em;
  font-weight: bold;
}
#assessmentForm .check-box-with-help {
  display: inline;
}
#assessmentForm .radio-help {
  margin-left: -1.5em;
  margin-right: 1.5em;
}
#assessmentForm .help-section {
  flex-grow: 0;
  min-width: 2em;
}
#assessmentForm .fa-envelope {
  margin-right: 1em;
}
#assessmentForm .choice-group {
  justify-content: flex-start;
}
#assessmentForm .choice-column {
  width: 25%;
}
#tutorial-dialog .flex-row {
  margin: auto;
  width: 90%;
  margin-bottom: 1em;
}
#tutorial-dialog .center {
  justify-content: center;
}
#tutorial-dialog .subtle {
  color: darkgray;
}
#tutorial-dialog .fa-times {
  cursor: pointer;
}
#app {
  background: #f5f5f5;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}
label {
  font-weight: bold;
}
h1 {
  font-size: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}
html {
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-justify-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-no-grow {
  flex-grow: 0 !important;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.app {
  min-height: 100%;
  width: 100%;
}
.content {
  min-height: 100% !important;
  width: 100%;
}
.main-container {
  min-height: 75em;
  background: #f5f5f5;
  border: 0px solid #000000;
  padding-left: 18em;
  padding-right: 2em;
  padding-bottom: 5em;
  padding-top: 1em;
  width: 100%;
}
@media (max-width: 1000px) {
  .main-container {
    padding: 0.5em;
  }
}
.boldLink {
  text-decoration: none;
  color: blue;
}
.no-sidenav {
  padding: 0.5em;
}
h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 1.5em;
}
.clickable {
  cursor: pointer;
}
.btn-logout {
  border: solid black 0.1em;
  padding: 0.5em 1em;
  color: black;
  margin: auto;
  width: 75px;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
  background: #f8f2ee;
  float: right;
  margin-top: 1.8em;
  margin-right: 3em;
}
@media only screen and (max-width: 1000px) {
  .btn-logout {
    display: none;
  }
}
li {
  text-decoration: none;
}
li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.successMessage {
  font-weight: bold;
  color: green;
}
.ai-generated-label {
  background-color: darkblue;
  border-radius: 0.5em;
  color: white;
  padding: 0.2em;
}
.ai-generated-warning {
  margin-top: 0.2em;
  font-size: 0.6em;
  font-weight: 300;
}
.errorMessage {
  font-weight: bold;
  color: #f8f2ee;
}
label {
  margin-right: 0.5em;
}
.dialog-title {
  font-weight: bold;
}
.subText {
  font-weight: 300;
}
.paper {
  margin-bottom: 1em;
  padding: 1em;
}
.paper h2 {
  margin-top: 0;
}
.section {
  margin-top: 1em;
}
.debug {
  display: none;
}
@media print {
  body {
    width: 10em;
  }
  body * {
    visibility: hidden;
  }
  .no-print,
  .no-print * {
    visibility: hidden !important;
  }
  .print,
  .print * {
    visibility: visible !important;
  }
  .no-print-hide {
    display: none;
  }
  .main-container,
  .main-container * {
    visibility: visible;
  }
  .main-container {
    position: absolute;
    left: -1em;
    top: 0;
  }
}
