#threatAssessmentReport {
    color: @textcolor;

    .MuiPaper-root{
        padding: 1em;
        margin-top: 1em;
    }

    h2{
        margin: 0;
    }

    .fa-edit{
        margin-left: 1em;
        font-size: 0.7em;
        color: black;
    }

    .threat-bar{
        height: 1.4em;
        padding-left: 2em;
    }

    .threat-bar-holder{
        width: 70%;
        border: solid rgb(238, 233, 233) 1px;
    }

    .rating-label {
        position: absolute;
    }

    ol, ul {
        padding-left: 0;
    }

    li {
        float: none;
    }

    .threatAssessmentAssessedFirstName{
        margin-left: 1em;
    }
    .threatAssessmentAssessedLastName{
        margin-left: 1em;
    }

    .interviewQuestion-subText{
        font-weight: 300;
        font-size: 0.8em;
        color: @dullcolor;
    }

    .interview{
        padding-bottom: 1em;
    }

    .interview-header, .answer-tally-header{
        font-size: 1.3em;
        font-weight: bold;
    }

    .threat-answer-tally{
        margin-top: 1em;
    }

    .question{
        padding: 1em 0;
    }

    .questionText{
        font-weight: bold;
    }

    .answer{
        font-size: .9em;
    }

    .fa-check{
        margin-left: .2em;
        color: red;
    }

    .interviewQuestion-footerText {
        font-weight: bold;
    }

    .resolutionClassification{
        font-weight: bold;
    }

    .actions{
        justify-content: flex-end;
        width: 100%;

        .flex-col{
            flex-grow: 0;
            width: auto;
        }
    }

    .cannotResolveMessage{
        color: @wrongAnswerText;
    }

}