    #tutorial-dialog{
        .flex-row{
            margin: auto;
            width: 90%;
            margin-bottom: 1em;
        }

        .center{
            justify-content: center;
        }

        .subtle{
            color: @dullcolor;
        }

        .fa-times{
            cursor: pointer;
        }
    }
