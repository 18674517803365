#threatAssessmentEdit{
    padding-left: 1em;

    .add-another{
        margin-bottom: 2em;
    }

    .addAnotherOther{
        font-size: .8em;
        color: @dullcolor;
        margin-top: .5em;
        margin-left: .5em;
        cursor: pointer;
    }

    .flex-row{
        justify-content: space-between;

        .flex-col{
            margin: .5em 1em .5em 0;
        }
        .flex-col:last-child{
            margin: .5em 0 .5em 0;
        }
    }

    .flex-col{
        flex-grow: 1;
        min-width: 10em;
    
        @media (max-width : 600px) {
            flex-grow: 0;
        }
    }

    .assessedFirstName-container{
        flex-grow: 2;
    }
    .assessedLastName-container{
        flex-grow: 2;
    }


    .assessmentFinding-text{
        width: 8em;
        margin-top: 1.2em;
    }

    .assessmentFinding-reviewed{
        width: 4em;
    }

    .reason, .dateOfBirth, .threatAssessmentTypeId, .institutionBuildingId, .institutionId, .requestorTitle, .gender, .threatLocation{
        width: 5em;
    }

    .threatPersonType {
        width: 15em;
        
        @media (max-width : 800px) {
            width: 5em;
        }
    }


    .threatPersonType-row{
        width: 40em;

        @media (max-width : 800px) {
            width: 100%;
        }
    }

    .delegateTitle{
        
        justify-content: flex-start;
        min-width: auto;
        flex-grow: 0;
    }

    .hasSubtitle {
        margin-bottom: 0;
    }
    .subText{
        color: @dullcolor;
    }
}

.dialog{
    padding: 0 2em 2em 2em;
    text-align: center;

    .dialog-text{
        margin-top: 1em;
    }

    .case-list{
        margin-top: 1em;
    }

    .case-links {
        list-style-type: none;
        padding: 0;

        li a {
            padding: 0;
            color: black;
        }
    }

    

    .header{
        font-weight: bold;
    }
}