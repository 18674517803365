#threatAssessmentInterview {
    color: @textcolor;
    padding-left: 1em;


    form{
        margin-bottom: 3em;
    }

    .flex-row{
        justify-content: space-between;

        .flex-col{
            margin: .5em 1em .5em 0;
        }
        .flex-col:last-child{
            margin: .5em 0 .5em 0;
        }
    }

    .flex-col{
        flex-grow: 1;
        min-width: 10em;
    
        @media (max-width : 600px) {
            flex-grow: 0;
        }
    }

    .helpToolTip{
        font-size: 0.6em;
    }

    textarea {
        height: 2em;
    }
  

    .interviewQuestions {
        padding-left: 0;
        margin-left: 0;
    }

    .interviewQuestion{
        margin-left: 1.5em;
    }

    .interviewQuestion-answer{
        margin-right: 1em;
    }

    .interviewQuestion-subText{
        font-weight: 300;
        font-size: 0.8em;
        color: @dullcolor;
    }

    .interviewQuestion-footerText {
        font-weight: bold;
    }

    .noshow{
        color: gray;
    }

    .save-container{
        margin-top: 1em;
    }

    .previouslyAnswered{
        color: @dullcolor;
        font-size: .8em;
    }

    .answer-text{
        margin-left: .5em;
    }

    .flex-content{
        margin-top: 1em;
    }

    .input-label{
        margin-right: .5em;
    }

    .radio-label{
        margin-left: .5em;
        margin-right: 2em;
    }

    .header{
        font-size: 1.1em;
        font-weight: bold;
    }

}