#threatAssessmentResolve {
    color: @textcolor;
    padding-left: 1em;

    h2{
        margin-bottom: 0;
    }

    .subText{
        font-weight: 300;
        font-size: 0.8em;
        color: @dullcolor;
    }

    form{
        margin-bottom: 3em;
    }

    .resolutionQuestions, .interventionQuestions {
        padding-left: 0;
        color: @textcolor;
        margin-left: 1em;
    }

    .resolutionQuestion{
        margin-top: 1em;
    }

    .interviewQuestion{
        margin-top: 1em;
    }

    .resolutionQuestion-subText{
        font-weight: 300;
        font-size: 0.8em;
        color: @dullcolor;
    }

    .resolutionQuestion-footerText {
        font-weight: bold;
    }

    .noshow{
        color: gray;
    }

    .save-container{
        margin-top: 1em;
    }

    .actionPlanOption{
        display: block;
        font-weight: normal;
    }

    .behaviorPlanParticipantOption{
        display: block;
        font-weight: normal;
        width: 10em;
    }

    .behaviorPlanParticipant-checkbox{
        margin-right: 1em;
    }

    .other-checkbox{
        margin-right: 1em;
    }

    .behaviorPlanParticipant-comment {
        width: 40em;
    }

    .actionPlans{
        margin: 0;
        padding-left: 1em;
    }

    .resolution-notes-section{
        width: 70%;
    }

}
