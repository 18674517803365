#threatAssessmentNotes {
    color: @textcolor;
    padding-left: 1em;

    h2{
        margin-bottom: 0;
    }

    .resolution-notes-section{
        width: 70%;
    }

}
