#threatAssessment {
    h2{
        margin-bottom: 0;
    }

    .flex-row{
        width: 80%;
        justify-content: space-between;

        @media (max-width : 600px) {
            width: 100%;
        }
    }

    .columns{
        .flex-row{
            width: 60%;
            justify-content: space-between;
    
            @media (max-width : 600px) {
                width: 100%;
            }
        }

        .flex-col{
            width: 30%;
        }    
    }
    
    .flex-col{
        padding-top: 1em;
    }

    a {
        color: @textcolor;
    }

    a:hover {
        color: @buttonHoverBackground;
    }

    .grade{
        color: @textcolor;
        margin-bottom: 1em;        
    }

    .instructions{
        margin-top: 1em;
        margin-right: 3em;
    }

    .close-case{
        margin-right: 2em;
        margin-bottom: 2em; 

        @media (max-width : 600px) {
            margin-right: 8em;   
            width: 100%;
        }
    }

    .delete{
        @media (max-width : 600px) {
            margin-right: 8em;    
        }
    }

    .btn-next{
        margin: 0;
    }

    .btn-next:hover {
        color: white;
    }


    .invite-interview{
        width: 20em;
    }

    .invite-button{
        margin-left: 1em;
    }

    .interview{
        color: @textcolor;
    }

    .actions{
        justify-content: flex-end;
        width: 100%;

        .flex-col{
            width: auto;
            padding: 1em 1em 1em 0;
        }
    }

    .case-closed{
        color: orangered;
        margin-bottom: .5em;
    }

    .files{
        margin: 0 0 5em 0;
    }

    .assets{
        justify-content: flex-start;
    }

    .uploaded-file-container{
        position: relative;
        width: 8em;
        margin-right: 2em;
        flex-grow: 0;
        border-bottom: 0;
        cursor: pointer;
    }

    .uploaded-file{
        color: @textcolor;
        font-size: 3em;
        padding: 0.4em;
        cursor: pointer;
        border: solid 0.01em gray;
        width: 1em;
    }

    .uploaded-file-caption{
        font-size: .7em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
    }

    .delete-image{
        position: absolute;
        top: 0.1em;
        right: 1.5em;
        font-size: 1.1em;
        cursor: pointer;
        padding: 0.3em;
        background: white;
        border-radius: 1em;
        color: black;
    }

    .file-upload-form{
        margin-top: 1em;
    }

    .btn-upload{
        width: 10em;
        margin-top: 1em;
    }

    .input-file{
        color: @textcolor;
    }

    .interview-text {
        padding: .5em 0;
    }

    .list-link{
        display: none;
        @media (max-width : 600px) {
            display: inline-block;
        }
    }

    .close-supplemental{
        margin-left: 6em;
        margin-top: 2.3em;
    }
  
    textarea {
        height: 4em;
    }

    .finding-item{
        margin-right: 1em;
    }

    .findings{
        list-style-type: none;
        margin: 0;
    }

    .finding-row{
        width: 100%;
    }

    .dropzonearea{
        margin-bottom: 1em;
        width: 50%;
        min-width: 40em;
    }

    .informationSource{
        width: 16em;
        display: inline-block;

        @media (max-width : 600px) {
            width: 100%;
        }
    }

    .reviewed{
        width: 8em;
        display: inline-block;
    }

    .comments{
        width: 100% !important;
    }

    .form-text {
        vertical-align: text-bottom;
    }

    .complete-check {
        margin-left: .3em;
        color: green;
        font-size: 1.2em;
    }

    .review:nth-child(n+2) {
        margin-top: .5em;
    }

}

.share-dialog{
    padding: 0 2em 2em 2em;
    text-align: center;
  
    .shareEmail{
        min-width: 20em;
    }

    .share-dialog-email{
      margin-top: 1em;
    }
  
    .share-button-container{
      margin-top: 1em;
    }
}