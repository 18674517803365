#acknowledge{
    .signature{
        width: 30em;
    }

    .signature-date{
        font-size: 1.5em;
        margin-left: 1em;
        margin-top: .5em;
    }
}